import { MantineProvider } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Outlet,
  createRootRouteWithContext,
  isMatch,
  useMatches,
} from '@tanstack/react-router';
import { Amplify } from 'aws-amplify';
import { lazy } from 'react';

import { ErrorComponent } from '../components/error-component';
import { IntlProvider } from '../context/Intl';
import { ModalsContext } from '../contexts/ModalsContext';
import { AuthData, authSessionQuery } from '../data/get-auth-session';
import { clientConfigQuery } from '../data/get-client-config';
import { theme } from '../theme';

const TanStackQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        })),
      );

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

type RouterContext = {
  queryClient: QueryClient;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  async beforeLoad({ context: { queryClient } }) {
    const config = await queryClient.ensureQueryData(clientConfigQuery());

    Amplify.configure(config.amplify);

    let authSession: AuthData | null;

    try {
      authSession = await queryClient.ensureQueryData(authSessionQuery());
    } catch (err) {
      authSession = null;
    }

    return { authSession, config };
  },
  errorComponent: ErrorComponent,
});

function RootComponent() {
  const { queryClient } = Route.useRouteContext();

  const matches = useMatches();
  const routesLoading = matches.some((match) => match.status === 'pending');
  const title = matches
    .filter(
      (match): match is typeof match & { loaderData: { title: unknown } } =>
        isMatch(match, 'loaderData.title'),
    )
    .at(-1)?.loaderData.title;

  useDocumentTitle(
    !routesLoading && title
      ? `${String(title)} · Hyper Portal`
      : 'Hyper Portal',
  );

  return (
    <>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <MantineProvider theme={theme}>
            <Notifications />
            <ModalsContext>
              <Outlet />
            </ModalsContext>
          </MantineProvider>
          <TanStackQueryDevtools initialIsOpen />
        </QueryClientProvider>
      </IntlProvider>
      <TanStackRouterDevtools />
    </>
  );
}
