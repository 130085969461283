import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

const MILLIS_PER_SECOND = 1_000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60;
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60;
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24;
const MILLIS_PER_WEEK = MILLIS_PER_DAY * 7;
const MILLIS_PER_MONTH = MILLIS_PER_DAY * 31;
const MILLIS_PER_YEAR = MILLIS_PER_DAY * 365;

function getTimeUnits(
  date: Date,
  now: Date = new Date(),
): [number, Intl.RelativeTimeFormatUnit] {
  const millis_diff = date.getTime() - now.getTime();

  switch (true) {
    case Math.abs(millis_diff) > MILLIS_PER_YEAR:
      return [Math.round(millis_diff / MILLIS_PER_YEAR), 'year'];
    case Math.abs(millis_diff) > MILLIS_PER_MONTH:
      return [Math.round(millis_diff / MILLIS_PER_MONTH), 'month'];
    case Math.abs(millis_diff) > MILLIS_PER_WEEK:
      return [Math.round(millis_diff / MILLIS_PER_WEEK), 'week'];
    case Math.abs(millis_diff) > MILLIS_PER_DAY:
      return [Math.round(millis_diff / MILLIS_PER_DAY), 'day'];
    case Math.abs(millis_diff) > MILLIS_PER_HOUR:
      return [Math.round(millis_diff / MILLIS_PER_HOUR), 'hour'];
    case Math.abs(millis_diff) > MILLIS_PER_MINUTE:
      return [Math.round(millis_diff / MILLIS_PER_MINUTE), 'minute'];
    default:
      return [Math.round(millis_diff / MILLIS_PER_SECOND), 'second'];
  }
}

type Ctx = {
  language: string;
};

const IntlContext = createContext<Ctx>({ language: navigator.language });

export function IntlProvider({
  language,
  children,
}: PropsWithChildren<{ language?: string }>) {
  return (
    <IntlContext.Provider value={{ language: language ?? navigator.language }}>
      {children}
    </IntlContext.Provider>
  );
}

/**
 * Get a formatted date time string for a date object.
 */
export function useDateTime(date: Date, options?: Intl.DateTimeFormatOptions) {
  const { language } = useContext(IntlContext);
  const dateTimeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        dateStyle: 'short',
        timeStyle: 'short',
        ...options,
      }),
    [language, options],
  );

  return useMemo(() => dateTimeFormat.format(date), [dateTimeFormat, date]);
}

/**
 * Helper component, wraps `useDateTime`, useful for maps in templates.
 */
export function DateTime({
  date,
  options = {},
}: {
  date: Date;
  options?: Intl.DateTimeFormatOptions;
}) {
  return useDateTime(date, options);
}

/**
 * Get a formatted relative time between two dates.
 */
export function useRelativeTime(
  date: Date,
  now?: Date,
  options?: Intl.RelativeTimeFormatOptions,
) {
  const { language } = useContext(IntlContext);
  const relativeTimeFormat = useMemo(
    () =>
      new Intl.RelativeTimeFormat(language, {
        style: 'short',
        numeric: 'auto',
        ...options,
      }),
    [language, options],
  );

  return useMemo(
    () => relativeTimeFormat.format(...getTimeUnits(date, now)),
    [relativeTimeFormat, date, now],
  );
}

/**
 * Helper component, wraps `useRelativeTime`, useful for maps in templates.
 */
export function RelativeTime({
  date,
  now,
  options,
}: {
  date: Date;
  now?: Date;
  options?: Intl.RelativeTimeFormatOptions;
}) {
  return useRelativeTime(date, now, options);
}
