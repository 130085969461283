import { queryOptions } from '@tanstack/react-query';

import { WorkspaceJoiningDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey() {
  return ['workspace', 'open'];
}

export function joinableWorkspacesQuery() {
  return queryOptions({
    queryKey: queryKey(),
    async queryFn() {
      const response = await axiosApiV1Client.get<Array<WorkspaceJoiningDto>>(
        '/workspace/joinable',
      );

      return response.data;
    },
  });
}

export {WorkspaceJoiningDto} from '@dentreality/hyper-portal-dtos';
