import { queryOptions } from '@tanstack/react-query';

import { VenueDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey(orgId: string) {
  return ['organisations', orgId, 'venues'];
}

export function venuesQuery(orgId: string) {
  return queryOptions({
    queryKey: queryKey(orgId),
    async queryFn() {
      const response = await axiosApiV1Client.get<VenueDto[]>(
        `/organisations/${orgId}/venues`,
      );

      return response.data;
    },
  });
}
