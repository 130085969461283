import { SearchParamError } from '@tanstack/react-router';
import { ZodError } from 'zod';

export function errorHasInvalidSearchParam(err: any, keys?: string[]) {
  if (err instanceof SearchParamError && err.cause instanceof ZodError) {
    if (keys) {
      const issueKeys = err.cause.issues.map((i) => i.path).flat();
      if (issueKeys.some((i) => keys.includes(i.toString()))) return true;
    }
    return true;
  }
  return false;
}
