import { queryOptions } from '@tanstack/react-query';

import { OrganisationDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey() {
  return ['workspace'];
}

export function workspacesQuery() {
  return queryOptions({
    queryKey: queryKey(),
    async queryFn() {
      const response =
        await axiosApiV1Client.get<OrganisationDto[]>(`/workspace`);

      return response.data;
    },
    placeholderData: [],
  });
}
