import { queryOptions } from '@tanstack/react-query';

import { PermissionsDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey() {
  return ['permissions'];
}

export function permissionsQuery() {
  return queryOptions({
    queryKey: queryKey(),
    queryFn: async () => {
      const { data } =
        await axiosApiV1Client.get<PermissionsDto>('/permissions');
      return data;
    },
  });
}
