import { Box, Button, ButtonProps, Group, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { Icon, IconCircleCheckFilled } from '@tabler/icons-react';
import { MouseEvent } from 'react';

import { openContextModal } from '..';

type Btn = ButtonProps & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

type ModalProps = {
  message: string;
  buttons?: (
    | Btn
    | ((context: ContextModalProps['context'], id: string) => Btn)
  )[];
};

export const SuccessAlertModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<ModalProps>) => {
  const { message } = innerProps;

  return (
    <Stack>
      <Stack align="center">{message}</Stack>
      <Group>
        {innerProps.buttons ? (
          innerProps.buttons.map((button) => {
            const _button =
              typeof button === 'function' ? button(context, id) : button;
            return (
              <Button
                {..._button}
                key={_button.children?.toString()}
                flex={1}
              />
            );
          })
        ) : (
          <Button fullWidth autoFocus onClick={() => context.closeModal(id)}>
            OK
          </Button>
        )}
      </Group>
    </Stack>
  );
};

export function openSuccessAlertModal({
  message,
  title,
  icon: Icon = IconCircleCheckFilled,
  buttons,
}: Omit<ModalProps, 'message'> & {
  message: string;
  title?: string;
  icon?: Icon;
}) {
  return openContextModal(
    'successAlert',
    { message, buttons },
    {
      title: (
        <Stack gap="xs" align="center">
          {Icon ? (
            <Box c="green.6">
              <Icon size="2em" />
            </Box>
          ) : null}
          {title}
        </Stack>
      ),
      styles: {
        root: {
          textAlign: 'center',
        },
        header: { display: 'flex', justifyContent: 'center' },
      },
    },
  );
}
