import { Button, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { Icon, IconAlertCircleFilled } from '@tabler/icons-react';

import { openContextModal } from '..';

export const ErrorAlertModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  message: string;
}>) => {
  const { message } = innerProps;

  return (
    <Stack>
      <Stack align="center">{message}</Stack>
      <Button color="red.9" autoFocus onClick={() => context.closeModal(id)}>
        OK
      </Button>
    </Stack>
  );
};

export function openErrorAlertModal({
  message,
  title,
  icon: Icon = IconAlertCircleFilled,
}: {
  message: string;
  title?: string;
  icon?: Icon;
}) {
  return openContextModal(
    'errorAlert',
    { message },
    {
      title: (
        <Stack gap="xs" align="center">
          {Icon ? <Icon size="2em" /> : null}
          {title ?? 'Error'}
        </Stack>
      ),
      styles: {
        root: {
          '--mantine-color-body': 'var(--mantine-color-red-6)',
          color: 'var(--mantine-color-white)',
          textAlign: 'center',
        },
        header: { display: 'flex', justifyContent: 'center' },
      },
    },
  );
}
