import { Button, Stack, ThemeIcon, Title } from '@mantine/core';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { ErrorComponentProps, Link } from '@tanstack/react-router';

export function AuthErrorComponent({ error }: ErrorComponentProps) {
  console.error(error);
  return (
    <Stack align="center">
      <ThemeIcon variant="transparent" size="2em" c="red">
        <IconAlertCircleFilled />
      </ThemeIcon>
      <Title order={4}>Something went wrong</Title>
      <Button component={Link} to="/auth/login" fullWidth>
        Return to Login
      </Button>
    </Stack>
  );
}
