import { queryOptions } from '@tanstack/react-query';

import {
  VenueOnboardingSteps,
  VenueOnboardingState,
} from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey(orgId: string, venueId: string) {
  return ['organisations', orgId, 'venues', venueId, 'onboarding'];
}

export function venueOnboardingStateQuery(orgId: string, venueId: string) {
  return queryOptions({
    queryKey: queryKey(orgId, venueId),
    async queryFn() {
      const response = await axiosApiV1Client.get<VenueOnboardingSteps>(
        `organisations/${orgId}/venues/${venueId}/onboarding`,
      );
      return response.data;
    },
  });
}

export const initialSteps: VenueOnboardingSteps = {
  mapDataImport: { state: VenueOnboardingState.AWAITING },
  abrLinking: { state: VenueOnboardingState.AWAITING },
  abrImport: { state: VenueOnboardingState.AWAITING },
  iosSurvey: { state: VenueOnboardingState.AWAITING },
  androidSurvey: { state: VenueOnboardingState.AWAITING },
};

export {
  VenueOnboardingState,
  VenueOnboardingStep,
} from '@dentreality/hyper-portal-dtos';
