import { ErrorAlertModal } from './ErrorAlertModal';
import { InfoAlertModal } from './InfoAlertModal';
import { SuccessAlertModal } from './SuccessAlertModal';

export const modals = {
  errorAlert: ErrorAlertModal,
  infoAlert: InfoAlertModal,
  successAlert: SuccessAlertModal,
};

export type Modals = typeof modals;

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}
