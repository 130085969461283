import { queryOptions } from '@tanstack/react-query';

import { ApiKeyDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey(orgId: string) {
  return ['organisations', orgId, 'api-keys'];
}

export function apiKeysQuery(orgId: string) {
  return queryOptions({
    queryKey: queryKey(orgId),
    async queryFn() {
      const response = await axiosApiV1Client.get<ApiKeyDto[]>(
        `iam/organisation/${orgId}/api-keys`,
      );
      return response.data;
    },
  });
}
