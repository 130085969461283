import { queryOptions } from '@tanstack/react-query';

import { WorkspaceInviteDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey(id: string) {
  return ['workspace', 'invite', id];
}

export function workspaceInviteQuery(id: string) {
  return queryOptions({
    queryKey: queryKey(id),
    async queryFn() {
      const response = await axiosApiV1Client.get<WorkspaceInviteDto>(
        `/workspace/invite/${id}`,
      );

      return response.data;
    },
    retry: false,
  });
}

export { WorkspaceInviteDto } from '@dentreality/hyper-portal-dtos';
