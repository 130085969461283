import { queryOptions } from '@tanstack/react-query';
import { fetchMFAPreference } from 'aws-amplify/auth';

export function queryKey() {
  return ['amplify', 'mfa'];
}

export function mfaStatusQuery() {
  return queryOptions({
    queryKey: queryKey(),
    queryFn: () => fetchMFAPreference(),
  });
}
