import { Box, Center, Code, MantineProvider, Text } from '@mantine/core';
import { ErrorComponentProps } from '@tanstack/react-router';

import logo from '../../public/logo.svg';
import { theme } from '../theme';

export function ErrorComponent({ error }: ErrorComponentProps) {
  return (
    <MantineProvider theme={theme}>
      <Box
        style={{
          width: '100svw',
          height: '100svh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'var(--mantine-color-gray-4)',
        }}
      >
        <Center mb="md">
          <img src={logo} style={{ width: 32 }} />
        </Center>
        <Box
          miw={320}
          p="sm"
          maw="40%"
          w="100%"
          bg="gray.1"
          style={{
            borderRadius: 'var(--mantine-radius-md)',
            display: 'flex',
            flexDirection: 'column',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'var(--mantine-color-gray-2',
          }}
        >
          <Text fw="bold" mb="xs" c="error">
            Something went wrong
          </Text>
          <Text>
            There was a problem loading the website. Please refresh or try again
            later.
          </Text>
          {import.meta.env.DEV ? (
            <Code mt="md">
              <details>
                <summary>Error Details</summary>
                {error.stack ?? error.message}
              </details>
            </Code>
          ) : null}
        </Box>
      </Box>
    </MantineProvider>
  );
}
