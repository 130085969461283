import { queryOptions, useQuery } from '@tanstack/react-query';

import type { ClientConfigDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey() {
  return ['client-config'];
}

export function clientConfigQuery() {
  return queryOptions({
    queryKey: queryKey(),
    async queryFn() {
      const response =
        await axiosApiV1Client.get<ClientConfigDto>('/client-config');
      return response.data;
    },
  });
}

export function useClientConfig() {
  return useQuery(clientConfigQuery());
}
