import { createRouter } from '@tanstack/react-router';

// Import the generated route tree
import { RoutePending } from './components/RoutePending';
import { queryClient } from './data/core';
import { routeTree } from './routeTree.gen';

// Create a new router instance
export const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPendingComponent: RoutePending,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
