import {
  Alert,
  AppShell,
  DefaultMantineColor,
  Input,
  MantineColorsTuple,
  PasswordInput,
  TextInput,
  createTheme,
  virtualColor,
} from '@mantine/core';

export const theme = createTheme({
  /* Put your mantine theme override here */
  fontFamily: '"Inter", sans-serif',

  colors: {
    success: virtualColor({ name: 'success', light: 'green', dark: 'green' }),
    error: virtualColor({ name: 'error', light: 'red', dark: 'red' }),
    destructive: virtualColor({
      name: 'destructive',
      light: 'red',
      dark: 'red',
    }),
    warning: virtualColor({ name: 'warning', light: 'yellow', dark: 'yellow' }),
    info: virtualColor({ name: 'info', light: 'blue', dark: 'blue' }),
  },

  components: {
    Alert: Alert.extend({ defaultProps: { variant: 'filled' } }),
    AppShell: AppShell.extend({
      defaultProps: {
        header: { height: 56 },
        navbar: { width: 60, breakpoint: 'sm' },
      },
    }),
    AppShellHeader: AppShell.Header.extend({
      defaultProps: {
        bd: '0',
      },
    }),
    AppShellNavbar: AppShell.Navbar.extend({
      defaultProps: {
        bd: 0,
        pt: 'md',
      },
    }),
    AppShellMain: AppShell.Main.extend({
      defaultProps: {
        style: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    }),
    Input: Input.extend({
      defaultProps: {
        bd: '0px',
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        bd: '0px',
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        bd: '0px',
      },
    }),
    Modal: {
      styles: {
        title: {
          fontWeight: 'bold',
          fontSize: 'var(--mantine-font-size-lg)',
        },
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        shadow: 'sm',
        p: 'sm',
      },
    },
  },
});

type ExtendedCustomColors =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}
