import {
  MantineModal,
  ModalsProvider,
  openContextModal as _ocm,
  modals as modalsManager,
} from '@mantine/modals';
import { PropsWithChildren } from 'react';

import { Modals, modals } from './modals';

export function ModalsContext({ children }: PropsWithChildren) {
  return <ModalsProvider modals={modals}>{children}</ModalsProvider>;
}

// HELPER

type OpenContextModalOptions<T extends MantineModal = keyof Modals> = Omit<
  Parameters<typeof _ocm<T>>[0],
  'modal'
>;

export function openContextModal<T extends keyof Modals = keyof Modals>(
  /**
   * Modal to open
   */
  modal: T,
  /**
   * Props to pass to modal
   */
  innerProps: OpenContextModalOptions<T>['innerProps'],
  /**
   * Additional options for the modal
   */
  options?: Omit<OpenContextModalOptions<T>, 'innerProps'>,
) {
  return modalsManager.openContextModal({
    modal,
    innerProps,
    withCloseButton: false,
    centered: true,
    ...options,
  });
}
