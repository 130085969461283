import { queryOptions } from '@tanstack/react-query';

import { OrganisationDto } from '@dentreality/hyper-portal-dtos';

import { axiosApiV1Client } from './core';

export function queryKey(workspaceId: string) {
  return ['workspace', workspaceId];
}

export function workspaceQuery(workspaceId: string) {
  return queryOptions({
    queryKey: queryKey(workspaceId),
    async queryFn() {
      const response = await axiosApiV1Client.get<OrganisationDto>(
        `/workspace/${workspaceId}`,
      );

      return response.data;
    },
  });
}
