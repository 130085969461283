/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/auth'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthIndexImport } from './routes/auth/index'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as AuthResetImport } from './routes/auth/reset'
import { Route as AuthRegisterImport } from './routes/auth/register'
import { Route as AuthMfaImport } from './routes/auth/mfa'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthForgotImport } from './routes/auth/forgot'
import { Route as AuthConfirmImport } from './routes/auth/confirm'
import { Route as AuthenticatedLandingImport } from './routes/_authenticated/landing'
import { Route as AuthenticatedInvitationImport } from './routes/_authenticated/invitation'
import { Route as AuthenticatedAdminImport } from './routes/_authenticated/admin'
import { Route as AuthenticatedAccountImport } from './routes/_authenticated/account'
import { Route as AuthenticatedOrgIdImport } from './routes/_authenticated/$orgId'
import { Route as AuthenticatedOrgIdIndexImport } from './routes/_authenticated/$orgId/index'
import { Route as AuthenticatedOrgIdVenuesImport } from './routes/_authenticated/$orgId/venues'
import { Route as AuthenticatedOrgIdCreateVenueImport } from './routes/_authenticated/$orgId/create-venue'
import { Route as AuthenticatedOrgIdApiKeysImport } from './routes/_authenticated/$orgId/api-keys'
import { Route as AuthenticatedAdminWorkspacesWorkspaceIdIndexImport } from './routes/_authenticated/admin/workspaces/$workspaceId/index'
import { Route as AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexImport } from './routes/_authenticated/admin/workspaces/$workspaceId/members/index'
import { Route as AuthenticatedAdminWorkspacesWorkspaceIdMembersAddImport } from './routes/_authenticated/admin/workspaces/$workspaceId/members/add'

// Create Virtual Routes

const AuthenticatedAdminIndexLazyImport = createFileRoute(
  '/_authenticated/admin/',
)()
const AuthenticatedAdminWorkspacesIndexLazyImport = createFileRoute(
  '/_authenticated/admin/workspaces/',
)()
const AuthenticatedAdminUsersIndexLazyImport = createFileRoute(
  '/_authenticated/admin/users/',
)()

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthResetRoute = AuthResetImport.update({
  id: '/reset',
  path: '/reset',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMfaRoute = AuthMfaImport.update({
  id: '/mfa',
  path: '/mfa',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotRoute = AuthForgotImport.update({
  id: '/forgot',
  path: '/forgot',
  getParentRoute: () => AuthRoute,
} as any)

const AuthConfirmRoute = AuthConfirmImport.update({
  id: '/confirm',
  path: '/confirm',
  getParentRoute: () => AuthRoute,
} as any)

const AuthenticatedLandingRoute = AuthenticatedLandingImport.update({
  id: '/landing',
  path: '/landing',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedInvitationRoute = AuthenticatedInvitationImport.update({
  id: '/invitation',
  path: '/invitation',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedAdminRoute = AuthenticatedAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedAccountRoute = AuthenticatedAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedOrgIdRoute = AuthenticatedOrgIdImport.update({
  id: '/$orgId',
  path: '/$orgId',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedAdminIndexLazyRoute =
  AuthenticatedAdminIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/admin/index.lazy').then((d) => d.Route),
  )

const AuthenticatedOrgIdIndexRoute = AuthenticatedOrgIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedOrgIdRoute,
} as any)

const AuthenticatedOrgIdVenuesRoute = AuthenticatedOrgIdVenuesImport.update({
  id: '/venues',
  path: '/venues',
  getParentRoute: () => AuthenticatedOrgIdRoute,
} as any)

const AuthenticatedOrgIdCreateVenueRoute =
  AuthenticatedOrgIdCreateVenueImport.update({
    id: '/create-venue',
    path: '/create-venue',
    getParentRoute: () => AuthenticatedOrgIdRoute,
  } as any)

const AuthenticatedOrgIdApiKeysRoute = AuthenticatedOrgIdApiKeysImport.update({
  id: '/api-keys',
  path: '/api-keys',
  getParentRoute: () => AuthenticatedOrgIdRoute,
} as any)

const AuthenticatedAdminWorkspacesIndexLazyRoute =
  AuthenticatedAdminWorkspacesIndexLazyImport.update({
    id: '/workspaces/',
    path: '/workspaces/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/admin/workspaces/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAdminUsersIndexLazyRoute =
  AuthenticatedAdminUsersIndexLazyImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/admin/users/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute =
  AuthenticatedAdminWorkspacesWorkspaceIdIndexImport.update({
    id: '/workspaces/$workspaceId/',
    path: '/workspaces/$workspaceId/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute =
  AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexImport.update({
    id: '/workspaces/$workspaceId/members/',
    path: '/workspaces/$workspaceId/members/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute =
  AuthenticatedAdminWorkspacesWorkspaceIdMembersAddImport.update({
    id: '/workspaces/$workspaceId/members/add',
    path: '/workspaces/$workspaceId/members/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/$orgId': {
      id: '/_authenticated/$orgId'
      path: '/$orgId'
      fullPath: '/$orgId'
      preLoaderRoute: typeof AuthenticatedOrgIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account': {
      id: '/_authenticated/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthenticatedAccountImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/admin': {
      id: '/_authenticated/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AuthenticatedAdminImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/invitation': {
      id: '/_authenticated/invitation'
      path: '/invitation'
      fullPath: '/invitation'
      preLoaderRoute: typeof AuthenticatedInvitationImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/landing': {
      id: '/_authenticated/landing'
      path: '/landing'
      fullPath: '/landing'
      preLoaderRoute: typeof AuthenticatedLandingImport
      parentRoute: typeof AuthenticatedImport
    }
    '/auth/confirm': {
      id: '/auth/confirm'
      path: '/confirm'
      fullPath: '/auth/confirm'
      preLoaderRoute: typeof AuthConfirmImport
      parentRoute: typeof AuthImport
    }
    '/auth/forgot': {
      id: '/auth/forgot'
      path: '/forgot'
      fullPath: '/auth/forgot'
      preLoaderRoute: typeof AuthForgotImport
      parentRoute: typeof AuthImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/auth/mfa': {
      id: '/auth/mfa'
      path: '/mfa'
      fullPath: '/auth/mfa'
      preLoaderRoute: typeof AuthMfaImport
      parentRoute: typeof AuthImport
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof AuthImport
    }
    '/auth/reset': {
      id: '/auth/reset'
      path: '/reset'
      fullPath: '/auth/reset'
      preLoaderRoute: typeof AuthResetImport
      parentRoute: typeof AuthImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/auth/': {
      id: '/auth/'
      path: '/'
      fullPath: '/auth/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_authenticated/$orgId/api-keys': {
      id: '/_authenticated/$orgId/api-keys'
      path: '/api-keys'
      fullPath: '/$orgId/api-keys'
      preLoaderRoute: typeof AuthenticatedOrgIdApiKeysImport
      parentRoute: typeof AuthenticatedOrgIdImport
    }
    '/_authenticated/$orgId/create-venue': {
      id: '/_authenticated/$orgId/create-venue'
      path: '/create-venue'
      fullPath: '/$orgId/create-venue'
      preLoaderRoute: typeof AuthenticatedOrgIdCreateVenueImport
      parentRoute: typeof AuthenticatedOrgIdImport
    }
    '/_authenticated/$orgId/venues': {
      id: '/_authenticated/$orgId/venues'
      path: '/venues'
      fullPath: '/$orgId/venues'
      preLoaderRoute: typeof AuthenticatedOrgIdVenuesImport
      parentRoute: typeof AuthenticatedOrgIdImport
    }
    '/_authenticated/$orgId/': {
      id: '/_authenticated/$orgId/'
      path: '/'
      fullPath: '/$orgId/'
      preLoaderRoute: typeof AuthenticatedOrgIdIndexImport
      parentRoute: typeof AuthenticatedOrgIdImport
    }
    '/_authenticated/admin/': {
      id: '/_authenticated/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AuthenticatedAdminIndexLazyImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/users/': {
      id: '/_authenticated/admin/users/'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof AuthenticatedAdminUsersIndexLazyImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/workspaces/': {
      id: '/_authenticated/admin/workspaces/'
      path: '/workspaces'
      fullPath: '/admin/workspaces'
      preLoaderRoute: typeof AuthenticatedAdminWorkspacesIndexLazyImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/workspaces/$workspaceId/': {
      id: '/_authenticated/admin/workspaces/$workspaceId/'
      path: '/workspaces/$workspaceId'
      fullPath: '/admin/workspaces/$workspaceId'
      preLoaderRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/workspaces/$workspaceId/members/add': {
      id: '/_authenticated/admin/workspaces/$workspaceId/members/add'
      path: '/workspaces/$workspaceId/members/add'
      fullPath: '/admin/workspaces/$workspaceId/members/add'
      preLoaderRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/workspaces/$workspaceId/members/': {
      id: '/_authenticated/admin/workspaces/$workspaceId/members/'
      path: '/workspaces/$workspaceId/members'
      fullPath: '/admin/workspaces/$workspaceId/members'
      preLoaderRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedOrgIdRouteChildren {
  AuthenticatedOrgIdApiKeysRoute: typeof AuthenticatedOrgIdApiKeysRoute
  AuthenticatedOrgIdCreateVenueRoute: typeof AuthenticatedOrgIdCreateVenueRoute
  AuthenticatedOrgIdVenuesRoute: typeof AuthenticatedOrgIdVenuesRoute
  AuthenticatedOrgIdIndexRoute: typeof AuthenticatedOrgIdIndexRoute
}

const AuthenticatedOrgIdRouteChildren: AuthenticatedOrgIdRouteChildren = {
  AuthenticatedOrgIdApiKeysRoute: AuthenticatedOrgIdApiKeysRoute,
  AuthenticatedOrgIdCreateVenueRoute: AuthenticatedOrgIdCreateVenueRoute,
  AuthenticatedOrgIdVenuesRoute: AuthenticatedOrgIdVenuesRoute,
  AuthenticatedOrgIdIndexRoute: AuthenticatedOrgIdIndexRoute,
}

const AuthenticatedOrgIdRouteWithChildren =
  AuthenticatedOrgIdRoute._addFileChildren(AuthenticatedOrgIdRouteChildren)

interface AuthenticatedAdminRouteChildren {
  AuthenticatedAdminIndexLazyRoute: typeof AuthenticatedAdminIndexLazyRoute
  AuthenticatedAdminUsersIndexLazyRoute: typeof AuthenticatedAdminUsersIndexLazyRoute
  AuthenticatedAdminWorkspacesIndexLazyRoute: typeof AuthenticatedAdminWorkspacesIndexLazyRoute
  AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute
  AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute
  AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute: typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute
}

const AuthenticatedAdminRouteChildren: AuthenticatedAdminRouteChildren = {
  AuthenticatedAdminIndexLazyRoute: AuthenticatedAdminIndexLazyRoute,
  AuthenticatedAdminUsersIndexLazyRoute: AuthenticatedAdminUsersIndexLazyRoute,
  AuthenticatedAdminWorkspacesIndexLazyRoute:
    AuthenticatedAdminWorkspacesIndexLazyRoute,
  AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute:
    AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute,
  AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute:
    AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute,
  AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute:
    AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute,
}

const AuthenticatedAdminRouteWithChildren =
  AuthenticatedAdminRoute._addFileChildren(AuthenticatedAdminRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedOrgIdRoute: typeof AuthenticatedOrgIdRouteWithChildren
  AuthenticatedAccountRoute: typeof AuthenticatedAccountRoute
  AuthenticatedAdminRoute: typeof AuthenticatedAdminRouteWithChildren
  AuthenticatedInvitationRoute: typeof AuthenticatedInvitationRoute
  AuthenticatedLandingRoute: typeof AuthenticatedLandingRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedOrgIdRoute: AuthenticatedOrgIdRouteWithChildren,
  AuthenticatedAccountRoute: AuthenticatedAccountRoute,
  AuthenticatedAdminRoute: AuthenticatedAdminRouteWithChildren,
  AuthenticatedInvitationRoute: AuthenticatedInvitationRoute,
  AuthenticatedLandingRoute: AuthenticatedLandingRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface AuthRouteChildren {
  AuthConfirmRoute: typeof AuthConfirmRoute
  AuthForgotRoute: typeof AuthForgotRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthMfaRoute: typeof AuthMfaRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
  AuthResetRoute: typeof AuthResetRoute
  AuthIndexRoute: typeof AuthIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthConfirmRoute: AuthConfirmRoute,
  AuthForgotRoute: AuthForgotRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthMfaRoute: AuthMfaRoute,
  AuthRegisterRoute: AuthRegisterRoute,
  AuthResetRoute: AuthResetRoute,
  AuthIndexRoute: AuthIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/$orgId': typeof AuthenticatedOrgIdRouteWithChildren
  '/account': typeof AuthenticatedAccountRoute
  '/admin': typeof AuthenticatedAdminRouteWithChildren
  '/invitation': typeof AuthenticatedInvitationRoute
  '/landing': typeof AuthenticatedLandingRoute
  '/auth/confirm': typeof AuthConfirmRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/mfa': typeof AuthMfaRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/reset': typeof AuthResetRoute
  '/': typeof AuthenticatedIndexRoute
  '/auth/': typeof AuthIndexRoute
  '/$orgId/api-keys': typeof AuthenticatedOrgIdApiKeysRoute
  '/$orgId/create-venue': typeof AuthenticatedOrgIdCreateVenueRoute
  '/$orgId/venues': typeof AuthenticatedOrgIdVenuesRoute
  '/$orgId/': typeof AuthenticatedOrgIdIndexRoute
  '/admin/': typeof AuthenticatedAdminIndexLazyRoute
  '/admin/users': typeof AuthenticatedAdminUsersIndexLazyRoute
  '/admin/workspaces': typeof AuthenticatedAdminWorkspacesIndexLazyRoute
  '/admin/workspaces/$workspaceId': typeof AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute
  '/admin/workspaces/$workspaceId/members/add': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute
  '/admin/workspaces/$workspaceId/members': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute
}

export interface FileRoutesByTo {
  '/account': typeof AuthenticatedAccountRoute
  '/invitation': typeof AuthenticatedInvitationRoute
  '/landing': typeof AuthenticatedLandingRoute
  '/auth/confirm': typeof AuthConfirmRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/mfa': typeof AuthMfaRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/reset': typeof AuthResetRoute
  '/': typeof AuthenticatedIndexRoute
  '/auth': typeof AuthIndexRoute
  '/$orgId/api-keys': typeof AuthenticatedOrgIdApiKeysRoute
  '/$orgId/create-venue': typeof AuthenticatedOrgIdCreateVenueRoute
  '/$orgId/venues': typeof AuthenticatedOrgIdVenuesRoute
  '/$orgId': typeof AuthenticatedOrgIdIndexRoute
  '/admin': typeof AuthenticatedAdminIndexLazyRoute
  '/admin/users': typeof AuthenticatedAdminUsersIndexLazyRoute
  '/admin/workspaces': typeof AuthenticatedAdminWorkspacesIndexLazyRoute
  '/admin/workspaces/$workspaceId': typeof AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute
  '/admin/workspaces/$workspaceId/members/add': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute
  '/admin/workspaces/$workspaceId/members': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/_authenticated/$orgId': typeof AuthenticatedOrgIdRouteWithChildren
  '/_authenticated/account': typeof AuthenticatedAccountRoute
  '/_authenticated/admin': typeof AuthenticatedAdminRouteWithChildren
  '/_authenticated/invitation': typeof AuthenticatedInvitationRoute
  '/_authenticated/landing': typeof AuthenticatedLandingRoute
  '/auth/confirm': typeof AuthConfirmRoute
  '/auth/forgot': typeof AuthForgotRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/mfa': typeof AuthMfaRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/reset': typeof AuthResetRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/auth/': typeof AuthIndexRoute
  '/_authenticated/$orgId/api-keys': typeof AuthenticatedOrgIdApiKeysRoute
  '/_authenticated/$orgId/create-venue': typeof AuthenticatedOrgIdCreateVenueRoute
  '/_authenticated/$orgId/venues': typeof AuthenticatedOrgIdVenuesRoute
  '/_authenticated/$orgId/': typeof AuthenticatedOrgIdIndexRoute
  '/_authenticated/admin/': typeof AuthenticatedAdminIndexLazyRoute
  '/_authenticated/admin/users/': typeof AuthenticatedAdminUsersIndexLazyRoute
  '/_authenticated/admin/workspaces/': typeof AuthenticatedAdminWorkspacesIndexLazyRoute
  '/_authenticated/admin/workspaces/$workspaceId/': typeof AuthenticatedAdminWorkspacesWorkspaceIdIndexRoute
  '/_authenticated/admin/workspaces/$workspaceId/members/add': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersAddRoute
  '/_authenticated/admin/workspaces/$workspaceId/members/': typeof AuthenticatedAdminWorkspacesWorkspaceIdMembersIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth'
    | '/$orgId'
    | '/account'
    | '/admin'
    | '/invitation'
    | '/landing'
    | '/auth/confirm'
    | '/auth/forgot'
    | '/auth/login'
    | '/auth/mfa'
    | '/auth/register'
    | '/auth/reset'
    | '/'
    | '/auth/'
    | '/$orgId/api-keys'
    | '/$orgId/create-venue'
    | '/$orgId/venues'
    | '/$orgId/'
    | '/admin/'
    | '/admin/users'
    | '/admin/workspaces'
    | '/admin/workspaces/$workspaceId'
    | '/admin/workspaces/$workspaceId/members/add'
    | '/admin/workspaces/$workspaceId/members'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/account'
    | '/invitation'
    | '/landing'
    | '/auth/confirm'
    | '/auth/forgot'
    | '/auth/login'
    | '/auth/mfa'
    | '/auth/register'
    | '/auth/reset'
    | '/'
    | '/auth'
    | '/$orgId/api-keys'
    | '/$orgId/create-venue'
    | '/$orgId/venues'
    | '/$orgId'
    | '/admin'
    | '/admin/users'
    | '/admin/workspaces'
    | '/admin/workspaces/$workspaceId'
    | '/admin/workspaces/$workspaceId/members/add'
    | '/admin/workspaces/$workspaceId/members'
  id:
    | '__root__'
    | '/_authenticated'
    | '/auth'
    | '/_authenticated/$orgId'
    | '/_authenticated/account'
    | '/_authenticated/admin'
    | '/_authenticated/invitation'
    | '/_authenticated/landing'
    | '/auth/confirm'
    | '/auth/forgot'
    | '/auth/login'
    | '/auth/mfa'
    | '/auth/register'
    | '/auth/reset'
    | '/_authenticated/'
    | '/auth/'
    | '/_authenticated/$orgId/api-keys'
    | '/_authenticated/$orgId/create-venue'
    | '/_authenticated/$orgId/venues'
    | '/_authenticated/$orgId/'
    | '/_authenticated/admin/'
    | '/_authenticated/admin/users/'
    | '/_authenticated/admin/workspaces/'
    | '/_authenticated/admin/workspaces/$workspaceId/'
    | '/_authenticated/admin/workspaces/$workspaceId/members/add'
    | '/_authenticated/admin/workspaces/$workspaceId/members/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/auth"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/$orgId",
        "/_authenticated/account",
        "/_authenticated/admin",
        "/_authenticated/invitation",
        "/_authenticated/landing",
        "/_authenticated/"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/confirm",
        "/auth/forgot",
        "/auth/login",
        "/auth/mfa",
        "/auth/register",
        "/auth/reset",
        "/auth/"
      ]
    },
    "/_authenticated/$orgId": {
      "filePath": "_authenticated/$orgId.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/$orgId/api-keys",
        "/_authenticated/$orgId/create-venue",
        "/_authenticated/$orgId/venues",
        "/_authenticated/$orgId/"
      ]
    },
    "/_authenticated/account": {
      "filePath": "_authenticated/account.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/admin": {
      "filePath": "_authenticated/admin.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/admin/",
        "/_authenticated/admin/users/",
        "/_authenticated/admin/workspaces/",
        "/_authenticated/admin/workspaces/$workspaceId/",
        "/_authenticated/admin/workspaces/$workspaceId/members/add",
        "/_authenticated/admin/workspaces/$workspaceId/members/"
      ]
    },
    "/_authenticated/invitation": {
      "filePath": "_authenticated/invitation.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/landing": {
      "filePath": "_authenticated/landing.tsx",
      "parent": "/_authenticated"
    },
    "/auth/confirm": {
      "filePath": "auth/confirm.tsx",
      "parent": "/auth"
    },
    "/auth/forgot": {
      "filePath": "auth/forgot.tsx",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/mfa": {
      "filePath": "auth/mfa.tsx",
      "parent": "/auth"
    },
    "/auth/register": {
      "filePath": "auth/register.tsx",
      "parent": "/auth"
    },
    "/auth/reset": {
      "filePath": "auth/reset.tsx",
      "parent": "/auth"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/auth/": {
      "filePath": "auth/index.tsx",
      "parent": "/auth"
    },
    "/_authenticated/$orgId/api-keys": {
      "filePath": "_authenticated/$orgId/api-keys.tsx",
      "parent": "/_authenticated/$orgId"
    },
    "/_authenticated/$orgId/create-venue": {
      "filePath": "_authenticated/$orgId/create-venue.tsx",
      "parent": "/_authenticated/$orgId"
    },
    "/_authenticated/$orgId/venues": {
      "filePath": "_authenticated/$orgId/venues.tsx",
      "parent": "/_authenticated/$orgId"
    },
    "/_authenticated/$orgId/": {
      "filePath": "_authenticated/$orgId/index.tsx",
      "parent": "/_authenticated/$orgId"
    },
    "/_authenticated/admin/": {
      "filePath": "_authenticated/admin/index.lazy.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/users/": {
      "filePath": "_authenticated/admin/users/index.lazy.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/workspaces/": {
      "filePath": "_authenticated/admin/workspaces/index.lazy.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/workspaces/$workspaceId/": {
      "filePath": "_authenticated/admin/workspaces/$workspaceId/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/workspaces/$workspaceId/members/add": {
      "filePath": "_authenticated/admin/workspaces/$workspaceId/members/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/workspaces/$workspaceId/members/": {
      "filePath": "_authenticated/admin/workspaces/$workspaceId/members/index.tsx",
      "parent": "/_authenticated/admin"
    }
  }
}
ROUTE_MANIFEST_END */
